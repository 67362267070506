'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:UnitDevicesCtrl

 # @description

###
class UnitDevicesCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    UnitsManager
    $q
  ) ->
    @progress = true
    @model = {}
    @errors = []

    init = =>
      @id = id

      @load = load
      @add = add
      @remove = remove
      @cancel = cancel
      @submit = submit

      @load()
    ##########

    add = ->
      @progress = true
      data = angular.copy @model
      data.device = data.device.id
      $q.when(UnitsManager.addDevice(@id, data))
        .then =>
          @load()
        , (resp) =>
          @progress = false
          @errors = extractErrors(resp.data.errors.children)

    remove = (contactId) ->
      @progress = true
      $q.when(UnitsManager.removeDevice(@id, contactId))
        .then =>
          @load()

    load = ->
      UnitsManager.one(@id).then(
        (data) =>
          @unitLabel = data.label
          @items = _.filter data.unitDevices, (item) -> !item.deletedAt?
          @progress = false
          @form = {}
          @model = {}
          @errors = []
          console.log @items
          @fields = UnitsManager.getDevicesForm(@items)

      )

    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'

    extractErrors = (errorsArray) ->
      errors = []
      for key, value of errorsArray
        if value.errors?
          errors.push('key': key, 'value': value.errors)
      errors

    init()
angular
  .module('mundoAdmin.units')
  .controller 'UnitDevicesCtrl', UnitDevicesCtrl
